import React from "react"; 
import Slider from "./Slider"; 

function Design(){
    return(
        <div id="design"> 
            <Slider /> 
            <div className='header-btns'>
                    <a href='https://www.suzanne-clemente.store' className='cv-btn'>Shop Now</a> 
                </div>
                <br /> 
        </div> 
    )
}

export default Design; 