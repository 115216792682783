import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  background-color: transparent;
  color: #fff;
  margin: 0 15px;
  margin-left: 10px; 
  margin-right: 10px; 
  font-size: 4em;
`;